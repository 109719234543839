<template>
    <div>
        <Header>
            <template v-slot:left>
                <i class="back iconfont icon-back" @click="backHandle"></i>
            </template>
            <template v-slot:mid>
                经典案例
            </template>
        </Header>

        <div class="col-wrap detial">
            <div class="content" v-if="detail">
                <h3>{{ detail.title }}</h3>
                <div class="summary" v-html="detail.desc"></div>
                <div class="wrap" v-html="detail.content"></div>
            </div>
            <div @click="backtop" class="blue-btn">回到顶部</div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "../../components/mobile/header.vue";
import Footer from "../../components/mobile/footer.vue";
export default {
    name: "mobile-case-detail",
    components: { Header, Footer },
    data() {
        return { loading: false, detail: null };
    },
    mounted() {
        this.loadDetail();
    },
    methods: {
        backHandle() {
            this.$router.back(-1);
        },
        backtop() {
            var timer = setInterval(function() {
                let osTop = document.documentElement.scrollTop || document.body.scrollTop;
                let ispeed = Math.floor(-osTop / 5);
                document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
                this.isTop = true;
                if (osTop === 0) {
                    clearInterval(timer);
                }
            }, 10);
        },
        loadDetail() {
            var id = this.$route.query.id;
            this.$showLoading();
            this.$http
                .get(`/api/case/detail/${id}`)
                .then((res) => {
                    this.detail = res.data.detail;
                })
                .finally(() => {
                    this.$hideLoading();
                });
        },
    },
};
</script>
<style scoped>
@import url("../../../common/css/mobile.css");
.detial .content {
    margin: 20px 15px;
    text-align: left;
}
.detial .content h3 {
    color: #333;
    font-size: 18px;
    margin-bottom: 10px;
}
.detial .content .summary {
    color: #0048b1;
    font-size: 14px;
    line-height: 24px;
    padding-bottom: 20px;
    margin-bottom: 30px;
    border-bottom: 1px #ccc solid;
}
.detial .content .wrap {
    color: #333;
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 40px;
}
.icon-back {
    font-size: 13px;
    padding: 5px 0;
}
.blue-btn {
    margin-bottom: 60px;
}
</style>
